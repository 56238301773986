<template>
  <div ref="chart" class="chart-container"></div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, nextTick } from 'vue'
import * as d3 from 'd3'

// remove hard code when component pages available
const variants = {
  bar: {
    marginTop: 15,
    marginRight: 10,
    marginBottom: -1,
    marginLeft: -1,
    paddingBetweenBars: 0.2,
    paddingBetweenCategories: 0.1,
    approxTickNumber: 5,
    negativeValuePadding: 0.1,
  },
  'rounded bar': {
    marginTop: 5,
    marginRight: 0,
    marginBottom: 20,
    marginLeft: 3,
    paddingBetweenBars: 0.05,
    paddingBetweenCategories: 0.1,
    approxTickNumber: 3,
    negativeValuePadding: 0.1,
  },
}

const props = defineProps({
  data: Array,
  options: Object,
})

const chart = ref(null)

function clearChart() {
  d3.select(chart.value).select('svg').remove()
}

/**
 * Wraps text within a specified width, adding line breaks as needed.
 * @param {Selection} text - D3 selection of the text elements.
 * @param {number} width - Maximum allowed width for the text.
 * @param {string} axisValuePosition - Angle of the axis value text.
 */
function wrap(text, width, axisValuePosition = 'normal') {
  text.each(function () {
    const textElem = d3.select(this)
    const originalText = textElem.text().trim()
    const words = originalText.split(/\s+/).reverse()

    let line = []
    let lineNumber = 0
    const lineHeight = 1.1

    const dy = 0

    // empty text element
    textElem.text(null)

    if (axisValuePosition === 'normal') {
      const y = parseFloat(textElem.attr('y')) + 10 || 0
      const x = parseFloat(textElem.attr('x')) || 0
      let tspan = textElem
        .append('tspan')
        .attr('x', x)
        .attr('y', y)
        .attr('dy', dy + 'em')

      let word
      while ((word = words.pop())) {
        line.push(word)
        tspan.text(line.join(' '))

        if (tspan.node().getComputedTextLength() > width) {
          line.pop()
          tspan.text(line.join(' '))

          line = [word]
          tspan = textElem
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + 'em')
            .text(word)
        }
      }
    } else {
      const y = parseFloat(textElem.attr('y')) || 0
      const x = parseFloat(textElem.attr('x')) || 0
      if (axisValuePosition === '45° angle') {
        textElem
          .attr('transform', `rotate(45, ${x + 10}, ${y + 5} )`)
          .attr('text-anchor', 'start')
          .attr('dominant-baseline', 'hanging')
          .text(originalText)
      } else if (axisValuePosition === '-45° angle') {
        textElem
          .attr('transform', `rotate(-45, ${x - 10}, ${y + 5} )`)
          .attr('text-anchor', 'end')
          .attr('dominant-baseline', 'hanging')
          .text(originalText)
      }
    }
  })
}

const filterByCategory = (data, category) => {
  if (!category) return data
  return data.flatMap(item => item.group.filter(subItem => subItem.category === category))
}

// Measure axes after the first render
function measureAxes(svg) {
  const xAxisNode = svg.select('.x-axis').node()
  const yAxisNode = svg.select('.y-axis').node()

  let xAxisBBox = { width: 0, height: 0 }
  let yAxisBBox = { width: 0, height: 0 }

  if (xAxisNode) xAxisBBox = xAxisNode.getBBox()
  if (yAxisNode) yAxisBBox = yAxisNode.getBBox()

  return { xAxisBBox, yAxisBBox }
}

// -------------------------------------
// Vertical Bar Chart
// -------------------------------------
function drawVerticalBarChart(svg, data, options, margins) {
  const { marginTop, marginRight, marginBottom, marginLeft } = margins

  const categories = new Set(data.flatMap(item => item.group.map(groupItem => groupItem.category)))
  const uniqueCategories = Array.from(categories)

  const allValues = data.flatMap(item => item.group.map(groupItem => groupItem.value))
  const minValue = Math.min(...allValues)
  const maxValue = Math.max(...allValues)

  const width = +svg.attr('width')
  const height = +svg.attr('height')

  const x0 = d3
    .scaleBand()
    .domain(data.map(d => d[options.x]))
    .range([marginLeft, width - marginRight])
    .padding(variants[props.options.variant].paddingBetweenCategories)

  const x1 = d3
    .scaleBand()
    .domain(uniqueCategories)
    .range([0, x0.bandwidth()])
    .padding(variants[props.options.variant].paddingBetweenBars)

  // option to add padding to handle labels for negative values
  const padding =
    options.negativeLabelBehaviour === 'padding'
      ? (maxValue - minValue) * (variants[options.variant].negativeValuePadding || 0.05)
      : 0
  const y = d3
    .scaleLinear()
    .domain([Math.min(0, minValue) - padding, Math.max(0, maxValue)])
    .nice()
    .range([height - marginBottom, marginTop])

  // Y grid
  svg
    .append('g')
    .attr('class', 'y-grid')
    .attr('transform', `translate(${marginLeft},0)`)
    .call(
      d3
        .axisLeft(y)
        .tickSize(-(width - marginLeft - marginRight))
        .tickFormat(() => ''),
    )
    .call(g => g.select('.domain').remove())

  // bars
  uniqueCategories.forEach((category, index) => {
    svg
      .append('g')
      .attr('class', `bar-bar`)
      .attr('fill', options.palette && options.palette[index] ? options.palette[index] : 'var(--primary)')
      .selectAll('rect')
      .data(filterByCategory(data, category))
      .enter()
      .append('rect')
      .attr('class', `group-${index} above`)
      .attr('x', d => x0(d[options.x]) + x1(category))
      .attr('y', d => (d[options.y] >= 0 ? y(d[options.y]) : y(0)))
      .attr('height', d => Math.abs(y(d[options.y]) - y(0)))
      .attr('width', x1.bandwidth())

    // smaller bars to unround bottom corners
    svg
      .append('g')
      .attr('class', `bar-bar`)
      .attr('fill', options.palette && options.palette[index] ? options.palette[index] : 'var(--primary)')
      .selectAll('rect')
      .data(filterByCategory(data, category))
      .enter()
      .append('rect')
      .attr('class', `bottom-${index} bottom`)
      .attr('x', d => x0(d[options.x]) + x1(category))
      .attr('y', d => (d[options.y] >= 0 ? y(d[options.y] / 2) : y(0)))
      .attr('height', d => Math.abs(y(d[options.y] / 2) - y(0)))
      .attr('width', x1.bandwidth())
  })

  // Bar labels
  uniqueCategories.forEach((category, index) => {
    svg
      .append('g')
      .attr('class', 'bar-label')
      .selectAll('text')
      .data(filterByCategory(data, category))
      .enter()
      .append('text')
      .attr('class', `group-${index}`)
      .attr('x', d => x0(d[options.x]) + x1(category) + x1.bandwidth() / 2)
      .attr('y', d => {
        if (d[options.y] >= 0) {
          return y(d[options.y]) - 3
        } else if (options?.negativeLabelBehaviour === 'positive-side') {
          return y(0) - 3
        } else {
          return y(d[options.y]) + 10
        }
      })
      .attr('text-anchor', 'middle')
      .text(d => (options.formatLabel ? options.formatLabel(d[options.y]) : d[options.y]))
  })

  // X axis
  const xAxis = svg
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height - marginBottom})`)
    .call(d3.axisBottom(x0).tickSizeOuter(0))

  xAxis.selectAll('.tick text').call(wrap, x0.bandwidth(), options.axisValuePosition)

  // Y axis
  const yAxis = svg
    .append('g')
    .attr('class', 'y-axis')
    .attr('transform', `translate(${marginLeft},0)`)
    .call(
      d3
        .axisLeft(y)
        .ticks(variants[options.variant]?.approxTickNumber ?? 5)
        .tickFormat(y => (options.formatLabel ? options.formatLabel(y) : y)),
    )

  yAxis.selectAll('.tick text').call(wrap, marginLeft)
}

async function createVerticalBarChart(data, options) {
  clearChart()
  const container = chart.value
  const width = container.clientWidth || 900
  const height = container.clientHeight || 300

  // Margin default value for the first render
  let marginTop = variants[options.variant].marginTop
  let marginRight = variants[options.variant].marginRight
  let marginBottom = variants[options.variant].marginBottom
  let marginLeft = variants[options.variant].marginLeft

  let svg = d3
    .select(container)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('style', 'max-width: 100%; max-height: 100%;')
    .attr('class', 'bar-container')

  drawVerticalBarChart(svg, data, options, { marginTop, marginRight, marginBottom, marginLeft })

  await nextTick()

  // Measure axes
  const { xAxisBBox, yAxisBBox } = measureAxes(svg)

  // Adjust margin if not set in variant
  marginLeft =
    variants[options.variant].marginLeft === -1 ? Math.ceil(yAxisBBox.width) : variants[options.variant].marginLeft
  marginBottom =
    variants[options.variant].marginBottom === -1 ? Math.ceil(xAxisBBox.height) : variants[options.variant].marginBottom

  clearChart()

  svg = d3
    .select(container)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('style', 'max-width: 100%; max-height: 100%;')
    .attr('class', 'bar-container')

  drawVerticalBarChart(svg, data, options, { marginTop, marginRight, marginBottom, marginLeft })
}

// -------------------------------------
// Horizontal Bar Chart
// -------------------------------------
function drawHorizontalBarChart(svg, data, options, margins) {
  const { marginTop, marginRight, marginBottom, marginLeft } = margins

  const categories = new Set(data.flatMap(item => item.group.map(groupItem => groupItem.category)))
  const uniqueCategories = Array.from(categories)

  const allValues = data.flatMap(item => item.group.map(groupItem => groupItem.value))
  const minValue = Math.min(...allValues)
  const maxValue = Math.max(...allValues)

  const width = +svg.attr('width')
  const height = +svg.attr('height')

  const y0 = d3
    .scaleBand()
    .domain(data.map(d => d[options.x]))
    .range([marginTop, height - marginBottom])
    .padding(variants[options.variant].paddingBetweenCategories)

  const y1 = d3
    .scaleBand()
    .domain(uniqueCategories)
    .range([0, y0.bandwidth()])
    .padding(variants[options.variant].paddingBetweenBars)

  // option to add padding to handle labels for negative values
  const padding =
    options.negativeLabelBehaviour === 'padding'
      ? (maxValue - minValue) * variants[options.variant].negativeValuePadding
      : 0
  const x = d3
    .scaleLinear()
    .domain([Math.min(0, minValue) - padding, Math.max(0, maxValue)])
    .nice()
    .range([marginLeft, width - marginRight])

  // Bars
  uniqueCategories.forEach((category, index) => {
    svg
      .append('g')
      .attr('class', `bar`)
      .attr('fill', options.palette && options.palette[index] ? options.palette[index] : 'var(--primary)')
      .selectAll('rect')
      .data(filterByCategory(data, category))
      .enter()
      .append('rect')
      .attr('class', `group-${index} above`)
      .attr('x', d => Math.min(x(0), x(d[options.y])))
      .attr('y', d => y0(d[options.x]) + y1(category))
      .attr('width', d => Math.abs(x(d[options.y]) - x(0)))
      .attr('height', y1.bandwidth())

    // smaller bars to unround bottom corners
    svg
      .append('g')
      .attr('class', `bar`)
      .attr('fill', options.palette && options.palette[index] ? options.palette[index] : 'var(--primary)')
      .selectAll('rect')
      .data(filterByCategory(data, category))
      .enter()
      .append('rect')
      .attr('class', `bottom-${index} bottom`)
      .attr('x', d => Math.min(x(0), x(d[options.y] / 2)))
      .attr('y', d => y0(d[options.x]) + y1(category))
      .attr('width', d => Math.abs(x(d[options.y] / 2) - x(0)))
      .attr('height', y1.bandwidth())
  })

  // X axis
  const xAxis = svg
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height - marginBottom})`)
    .call(
      d3
        .axisBottom(x)
        .ticks(variants[options.variant].approxTickNumber ?? 5)
        .tickFormat(x => (options.formatLabel ? options.formatLabel(x) : x)),
    )
  xAxis.selectAll('.tick text').call(wrap, y0.bandwidth(), options.axisValuePosition)

  // Y axis
  const yAxis = svg
    .append('g')
    .attr('class', 'y-axis')
    .attr('transform', `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y0))

  yAxis.selectAll('.tick text').call(wrap, marginLeft)

  // Bar labels
  uniqueCategories.forEach((category, index) => {
    svg
      .append('g')
      .attr('class', `bar-label`)
      .selectAll('text')
      .data(filterByCategory(data, category))
      .enter()
      .append('text')
      .attr('class', `group-${index}`)
      .attr('x', d => {
        if (d[options.y] >= 0) {
          return x(d[options.y]) + 5
        } else if (options?.negativeLabelBehaviour === 'positive-side') {
          return x(0) + 5
        } else {
          return x(d[options.y]) - 5
        }
      })
      .attr('y', d => y0(d[options.x]) + y1(category) + y1.bandwidth() / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', d =>
        d[options.y] >= 0 || options?.negativeLabelBehaviour === 'positive-side' ? 'start' : 'end',
      )
      .text(d => (options.formatLabel ? options.formatLabel(d[options.y]) : d[options.y]))
  })
}

async function createHorizontalBarChart(data, options) {
  clearChart()
  const container = chart.value
  const width = container.clientWidth || 900
  const height = container.clientHeight || 400

  // Margin default value for the first render
  let marginTop = variants[options.variant].marginTop || 0
  let marginRight = variants[options.variant].marginRight || 20
  let marginBottom = variants[options.variant].marginBottom || 30
  let marginLeft = variants[options.variant].marginLeft || 40

  let svg = d3
    .select(container)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('style', 'max-width: 100%; max-height: 100%;')
    .attr('class', 'bar-container')

  drawHorizontalBarChart(svg, data, options, { marginTop, marginRight, marginBottom, marginLeft })

  await nextTick()

  // Measure axes
  const { xAxisBBox, yAxisBBox } = measureAxes(svg)

  // Adjust margin if not set in variant
  marginLeft =
    variants[options.variant].marginLeft === -1 ? Math.ceil(yAxisBBox.width) : variants[options.variant].marginLeft
  marginBottom =
    variants[options.variant].marginBottom === -1 ? Math.ceil(xAxisBBox.height) : variants[options.variant].marginBottom

  clearChart()

  svg = d3
    .select(container)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('style', 'max-width: 100%; max-height: 100%;')
    .attr('class', 'bar-container')

  drawHorizontalBarChart(svg, data, options, { marginTop, marginRight, marginBottom, marginLeft })
}

function handleResize() {
  if (!props.options.horizontal) {
    createVerticalBarChart(props.data, props.options)
  } else {
    createHorizontalBarChart(props.data, props.options)
  }
}

onMounted(() => {
  handleResize()
})

watch(
  () => [props.data, props.options.horizontal, props.options.axisValuePosition],
  () => {
    handleResize()
  },
)
</script>
