import axios from 'axios'
import config from '../config'

export const partialToQl = partial =>
  Object.entries(partial).reduce((str, b) => `${str}\n ${b[0]}: ${JSON.stringify(b[1])}`, '')

/**
 * Rest base service
 */
export class RestService {
  _headers() {
    return {
      'x-caller-url': window.location.href,
      'x-client': import.meta.env.VITE_PROJECT,
      // NOTE: authorization is already injected by an axios interceptor
    }
  }

  /**
   * @param method The HTTP method to use
   * @param endpoint The REST route of the API
   * @param payload The JSON payload to send
   * @param options The options to pass to axios
   * @return {Promise<any>} Any response
   */
  async _createOrUpdate(method, endpoint, payload, options = {}) {
    try {
      const res = await axios[method.toLowerCase()](`${config.graphqlEndpoint}/${endpoint}`, payload, {
        headers: this._headers(),
        ...options,
      })
      return res?.data
    } catch (error) {
      // Todo: Better error handling with precise error messages
      if (error.response?.data?.error) {
        throw new Error(error.response.data.error)
      }

      throw new Error(error.message)
    }
  }

  /**
   * @param endpoint The REST route of the API
   * @param payload The JSON payload to send
   * @param options The options to pass to axios
   * @return {Promise<any>} Any response
   */
  async post(endpoint, payload, options = {}) {
    // NOTE: POST must be used only for resource creations (REST API guidelines)
    return await this._createOrUpdate('POST', endpoint, payload, options)
  }

  /**
   * @param endpoint The REST route of the API
   * @param id The id of the resource to update
   * @param payload The JSON payload to send
   * @param options The options to pass to axios
   * @return {Promise<any>} Any response
   */
  async put(endpoint, id, payload, options = {}) {
    // NOTE: PUT must be used only for full resource updates (REST API guidelines)
    await this._createOrUpdate('PUT', `${endpoint}/${id}`, payload, options)
  }

  /**
   * @param endpoint The REST route of the API
   * @param id The id of the resource to update
   * @param payload The JSON payload to send
   * @param options The options to pass to axios
   * @return {Promise<any>} Any response
   */
  async patch(endpoint, id, payload, options = {}) {
    // NOTE: PATCH must be used only for partial resource updates (REST API guidelines)
    await this._createOrUpdate('PATCH', `${endpoint}/${id}`, payload, options)
  }

  /**
   * @param endpoint The REST route of the API
   * @param options The options to pass to axios
   * @return {Promise<any>} Any response
   */
  async get(endpoint, options = {}) {
    try {
      const res = await axios.get(`${config.graphqlEndpoint}/${endpoint}`, {
        headers: this._headers(),
        ...options,
      })
      return res?.data
    } catch (error) {
      throw new Error(error.message)
    }
  }

  /**
   * @param endpoint The REST route of the API
   * @param id The id of the resource to delete
   * @param options The options to pass to axios
   * @return {Promise<any>} Any response
   */
  async delete(endpoint, id = null, options = {}) {
    try {
      const url = id ? `${config.graphqlEndpoint}/${endpoint}/${id}` : `${config.graphqlEndpoint}/${endpoint}`
      const res = await axios.delete(url, {
        headers: this._headers(),
        ...options,
      })
      return res?.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
}

export const downloadResponseAsFile = (response, type, name) => {
  const downloadElement = document.createElement('a')
  downloadElement.style.display = 'none'
  downloadElement.href = window.URL.createObjectURL(new Blob([response], { type }))
  downloadElement.download = name
  document.body.appendChild(downloadElement)
  downloadElement.click()
  window.URL.revokeObjectURL(downloadElement.href)
  downloadElement.remove()
}

export const restService = new RestService()
