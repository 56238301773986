<template>
  <div id="container" class="builder-heatmap">
    <div v-if="error" class="w-full !bg-red-50 font-bold text-red-600">
      The table hight exceeds the allocated space height.
      <br />
      You can :
      <ul>
        <li>* Change layout</li>
        <li>* Increase block height</li>
        <li>* decrese number of lines (Y Limit)</li>
      </ul>
    </div>
    <table
      v-else
      id="contained"
      ref="heatmapTableRef"
      class="heatmap-table w-full table-fixed border-collapse border-2 text-center leading-3"
    >
      <tr class="heatmap-header">
        <td class="border-b-2"></td>
        <td
          class="overflow-hidden border-b-2 py-3"
          :class="{ 'border-x-2': column === 'total' }"
          v-for="column in columns"
          :key="column"
          v-html="translate(column)"
        ></td>
      </tr>
      <tr v-for="line in lines" :key="line" class="heatmap-table-row">
        <td
          class="heatmap-header overflow-hidden"
          :class="{ 'border-y-2': line === 'total' }"
          v-html="translate(line)"
        ></td>
        <td
          v-for="(column, index) in columns"
          :key="column"
          class="heatmap-cell overflow-hidden py-3"
          :class="{
            'heatmap-column-header border-y-2': line === 'total',
            'heatmap-cell-column-total total-r border-x-2': column === 'total',
            'heatmap-cell-value': column !== 'total' && line !== 'total',
          }"
          :style="{
            ...(line !== 'total' &&
              column !== 'total' && {
                'background-color': heatColor(final?.[line]?.[column], ...minMaxDisplayed),
              }),
          }"
        >
          {{ format('.' + (props.options.digit ?? 1) + (props.options.unit ?? ''))(final?.[line]?.[column]) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, defineProps } from 'vue'
import { useWatchBuilderHeatmapComponentProps } from '../composables/builderComponent'
import { getXValues } from '../composables/builderOptions'
import useTranslations from '../composables/translations'
import { generatePaletteLinear } from '../lib/linearGradientColorCalculate'

const DEFAULT_HEATMAP_PALETTE = ['#f7f7f7', '#084081']
const DEFAULT_HEATMAP_PALETTE_LINEAR_COLOR_STEPS = 100

const error = ref(false)
const definedProps = defineProps(['data', 'options', 'context'])
const props = useWatchBuilderHeatmapComponentProps(definedProps)
const { translate } = useTranslations(definedProps)

onMounted(() => {
  try {
    const containerDim = document.querySelector('#container').getBoundingClientRect()
    const containedDim = document.querySelector('#contained').getBoundingClientRect()
    if (containedDim.height > containerDim.height) {
      throw new Error(`dépassement`)
    }
  } catch (e) {
    error.value = true
    return error
  }
})

const lines = computed(() => {
  const options = props.options
  let res = Object.keys(props.data?.group(options.y) || {})
  if (options.yLimit && res.length > options.yLimit) {
    res = res.slice(0, options.yLimit - +options.showYOther)
    if (options.showYOther) res.push('other')
  }
  if (options.showYTotal) res.push('total')
  return res
})
const columns = computed(() => {
  const options = props.options
  let res = Object.keys(props.data?.group(options.x) || {})
  if (options.xLimit && res.length > options.xLimit) {
    res = res.slice(0, options.xLimit - +options.showXOther)
    if (options.showXOther) res.push('other')
  }
  if (options.showXTotal) res.push('total')
  return res
})
const final = computed(() => {
  const options = props.options
  let res = {}
  const groupedY = props.data?.group(options.y) || {}
  // fill raw lines
  for (const line of lines.value) {
    if (line === 'total') continue
    if (line === 'other') {
      res['other'] = Object.entries(groupedY)
        .filter(([key, arr]) => !lines.value.includes(key))
        .reduce((acc, [key, value]) => {
          acc.push(value)
          return acc
        }, [])
        .flat()
    } else {
      res[line] = groupedY[line]
    }
  }
  // calculate the Y Axis total value
  if (options.showYOther) {
    res['total'] = props.data
  } else {
    res['total'] = Object.values(res).flat()
  }
  // group each line and compute heat
  for (const line of lines.value) {
    let resLine = {}
    const groupedX = res[line]?.group(options.x) || {}
    for (const column of columns.value) {
      if (column === 'total') continue
      if (column === 'other') {
        const others = Object.entries(groupedX).filter(([key, arr]) => !columns.value.includes(key))
        resLine['other'] = others.length
          ? others.reduce((acc, [key, value]) => {
              acc += value.sum(options.z)
              return acc
            }, 0)
          : null
      } else {
        resLine[column] = groupedX[column]?.sum(options.z) || null
      }
    }
    // calculate the X Axis total value
    if (options.showXOther) {
      resLine['total'] = res[line]?.sum(options.z) || null
    } else {
      const others = Object.entries(groupedX).filter(([key, arr]) => columns.value.includes(key))
      resLine['total'] = others.length
        ? others.reduce((acc, [key, value]) => {
            acc += value.sum(options.z)
            return acc
          }, 0)
        : null
    }
    res[line] = resLine
  }

  return res
})
const minMaxDisplayed = computed(() => {
  const res = Object.entries(final.value)
    .filter(([key, val]) => key !== 'total')
    .map(([key, val]) =>
      Object.entries(val)
        .filter(([key, val]) => key !== 'total')
        .map(([key, val]) => val),
    )
    .flat()
  return [Math.min(...res), Math.max(...res)]
})

const heatPalette = computed(() => {
  const [lightColor = DEFAULT_HEATMAP_PALETTE[0], darkColor = DEFAULT_HEATMAP_PALETTE[1]] = props.options.palette || []
  return generatePaletteLinear(darkColor, lightColor, DEFAULT_HEATMAP_PALETTE_LINEAR_COLOR_STEPS)
})

const heatColor = (value, min, max) => {
  if (!value) return
  const heatPaletteValue = heatPalette.value
  let index = Math.floor(((value - min) / (max - min)) * (heatPaletteValue.length - 1))
  return `${heatPaletteValue[index]}`
}
</script>

<script>
import { heatmap } from './stories'

export default {
  api: {
    x: {
      label: 'X Axis',
      select: getXValues,
      default: data => getXValues(data)[0],
      attrs: {
        required: true,
      },
    },
    xLimit: {
      label: 'X Limit',
      default: () => 10,
      attrs: {
        type: 'number',
      },
    },
    showXOther: {
      label: 'X Other',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    showXTotal: {
      label: 'X Total',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    y: {
      label: 'Y Axis',
      select: getXValues,
      default: data => getXValues(data)[1],
      attrs: {
        required: true,
      },
    },
    yLimit: {
      label: 'Y Limit',
      default: () => 6,
      attrs: {
        type: 'number',
      },
    },
    showYOther: {
      label: 'Y Other',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    showYTotal: {
      label: 'Y Total',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    z: {
      label: 'Z Axis',
      select: ({ data_component }) => {
        return (
          data_component &&
          Object.entries(data_component[0] || {})
            .filter(([k, v]) => typeof v === 'number')
            .map(([k, v]) => k)
        )
      },
      default: () => 'weight',
      attrs: {
        required: true,
      },
    },
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 2,
      attrs: {
        type: 'number',
      },
    },
  },
  styles: {
    'heatmap-header': {
      name: 'Heatmap Header',
      css: `font-weight: bold;`,
    },

    'heatmap-cell': {
      name: 'Heatmap cell',
      css: '',
    },
    'heatmap-table': {
      name: 'Heatmap',
      css: '',
    },
    'heatmap-dark-color': {
      name: 'Heatmap Dark Color',
      css: 'background-color: #084081;',
    },
    'heatmap-light-color': {
      name: 'Heatmap Light Color',
      css: 'background-color: #f7f7f7;',
    },
  },
  story: heatmap,
}
</script>
